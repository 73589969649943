import { graphql } from 'gatsby'
import React from "react";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderThree from "@/components/header-three";
import Footer from "@/components/footer";
import PortableText from '@/components/portableText'

import Seo from '@/components/seo';

import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'

const LiveEvent = ({location, data, qty}) => {

  const liveEventPage = data.sanityLiveStream;
  const highlightBlock = data.sanityLiveStream.highlightBlock;

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }



  return (
    <MenuContextProvider>
      <Seo
        title="Ferrara Fiorenza PC"
        description="Ferrara Fiorenza PC offers a blend of expertise and thoughtful representation in analyzing and solving the challenges faced by school districts, BOCES and all types of employers."
        location={location}
      />
      <Layout PageTitle={liveEventPage.title}>
      <HeaderThree />

        <div className='w-full' dangerouslySetInnerHTML={{__html: liveEventPage.iframeBlock}} />
       
        {/* About */}
        {liveEventPage?.highlightBlock &&
          <section className="about_area section_padding">
            {highlightBlock.map(({ title, tagline, image, link, _rawContent, cta }, index) => (
            <div className="container mb-12" key={index}>
              <div className="row">
                <div className="col-md-6">
                  <div style={image?.asset && { backgroundImage: `url(${image.asset.url})`, backgroundPosition: 'center' }}  className="about_image rounded about-image__updated"></div>
                </div>
                <div className="col-md-6">
                  <div className="about_details">
                    <div className="hero-title-with-shape">
                      <h4 className="heading_with_border">{tagline}</h4>
                      <h1>{title}</h1>
                    </div>
                    <PortableText blocks={_rawContent} />
                    

                    {cta?.link.slug !== null && link._type === "post" &&
                    <a href={"articles/"+link.slug.current} className="btn-yellow">
                      {cta}
                    </a>}

                    {cta?.link.slug !== null && link._type === "page" &&
                    <a href={link.slug.current} className="btn-yellow">
                      {cta}
                    </a>}

                    {cta?.link.slug !== null && link._type === "event" &&
                    <a href={"articles/"+link.slug.current} className="btn-yellow">
                      {cta}
                    </a>}

                  </div>
                </div>
              </div>
            </div>
             ))}
          </section>
        }
        
        {/* Call to Action */}
        {liveEventPage?.actionBanner &&
        <section className="call_to_action">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-8 col-xs-12">
                <h1>{liveEventPage.actionBanner.title}</h1>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 text-end cta_responsive_left">
                {liveEventPage.actionBanner.link._type === "post" &&
                <a href={"articles/"+liveEventPage.actionBanner.link.slug.current} className="btn-gray text-md">
                    {liveEventPage.actionBanner.cta}
                </a>}

                {liveEventPage.actionBanner.link._type === "page" &&
                <a href={liveEventPage.actionBanner.link.slug.current} className="btn-gray text-md">
                    {liveEventPage.actionBanner.cta}
                </a>}

                {liveEventPage.actionBanner.link._type === "event" &&
                <a href={"articles/"+liveEventPage.actionBanner.link.slug.current} className="btn-gray text-md">
                    {liveEventPage.actionBanner.cta}
                </a>}
              </div>
            </div>
          </div>
        </section>
        }
        {/* Contact */}
        <section className="contact_form_area">
          <div className="contact_form_width">
            <div id="map">
              <iframe
                title="template google map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11654.541282051277!2d-76.0770375!3d43.0911617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9a9bfafb203aade2!2sFerrara%20Fiorenza%20PC!5e0!3m2!1sen!2sus!4v1647870944706!5m2!1sen!2sus"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="contact_form_width contact-right">
            <div className="hero-title-with-shape">
              <h4 className="heading_with_border">Work with Ferrara</h4>
              <h1 className="mb-6">Connect with us</h1>
            </div>
            <div>
              <div className="contact-right contact-right-style-2 responsive_mt">
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="Homepage-Contact">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="Homepage-Contact" />
                  <input
                    className="half_width input_m_right"
                    type="text"
                    name="name"
                    placeholder="Your name"
                  />
                  <input
                    className="half_width"
                    type="EMAIL"
                    name="email"
                    placeholder="Email address"
                  />
                  <input type="tel" name="tel" placeholder="Phone number" />
                  <textarea
                    name="content"
                    id="content"
                    cols="20"
                    rows="5"
                    placeholder="Write message"
                    className='w-full p-4'
                  ></textarea>
                  <button className="btn-yellow mt-3" value="SUBMIT">
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default LiveEvent;

export const query = graphql`
query LiveStreamEvent {
    sanityLiveStream {
      actionBanner {
        cta
        title
        link {
          ... on SanityAttorney {
            _type
            slug {
              current
            }
          }
          ... on SanityPage {
            _type
            slug {
              current
            }
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
        }
      }
      iframeBlock
      highlightBlock {
        title
        tagline
        link {
          ... on SanityAttorney {
            _type
            slug {
              current
            }
          }
          ... on SanityPage {
            _type
            id
            slug {
              current
            }
          }
          ... on SanityPost {
            _type
            id
            slug {
              current
            }
          }
        }
        cta
        image {
          asset {
            url
          }
        }
        _rawContent
      }
      title
    }
  }
  
  
`